<template>
    <div :class="uniqueContainerClassName"></div>
</template>

<script setup lang="ts">
    import lottie from 'lottie-web';
    import { onMounted } from 'vue';
    import { v4 as uuidv4 } from 'uuid';

    const props = defineProps({
  containerClassName: null,
  startOnInit: { type: Boolean, default: true },
  loop: { type: Boolean, default: true },
  json: null,
  speed: { default: 1 }
});

    const start = async (): void => {
        const container = document.querySelector(`.${uniqueContainerClassName.value}`);
        if (!container) {
            await wait(100);
            start();
            return;
        }

        const animation = lottie.loadAnimation({
            container,
            renderer: 'svg',
            loop: props.loop,
            autoplay: true,
            path: props.json,
        });

        if (props.speed !== 1) {
            animation.setSpeed(props.speed);
        }
    };

    const uniqueContainerClassName = computed(() => {
        return props.containerClassName || `lottie-animation-container-${uuidv4()}-${Math.floor(Math.random() * 100)}`;
    });

    onMounted(() => {
        if (props.startOnInit) {
            start();
        }
    });
</script>
